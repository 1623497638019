import { CARS } from './../../cars';
import { PLACES } from './../../places';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';

@Component({
  selector: 'app-route-detail-page',
  templateUrl: './route-detail-page.component.html',
  styleUrls: ['./route-detail-page.component.css']
})
export class RouteDetailPageComponent implements OnInit {
  routeDetails: any;
  places;
  cars = CARS as any;
  carEstimation;
  roundTripDistance;
  totalDistance;
  totalDuration;
  tripEstimation;

  constructor(private activeRoute: ActivatedRoute, public dialog: MatDialog) { }

  ngOnInit (): void {
    let routeUrl = this.activeRoute.snapshot.paramMap.get('routeDetails');
    let city = this.activeRoute.snapshot.paramMap.get('cityName');
    PLACES.filter(citys => citys.city === city).map(item => {
      console.log(item);
      this.places = item.routes
    });
    this.routeDetails = this.places?.filter(route => route.url === routeUrl)[0];
  }

  onResponses (event) {
    this.totalDistance = (event.routes[0].legs[0].distance.text).replace(/[^0-9.-]+/g,"");
    this.totalDuration = event.routes[0].legs[0].duration.text;
    this.roundTripDistance = this.totalDistance.replace(/[^0-9.-]+/g,"") * 2;

    this.cars.forEach(car => {
      car['onewaytripCost'] = car['one-way'] *  this.totalDistance.replace(/[^0-9.-]+/g,"") + car.onewayDriverFee;
      car['roundtripCost'] = car['round-trip'] * (this.totalDistance.replace(/[^0-9.-]+/g,"") * 2) + car.roundTripDriverFee;
    });

    this.tripEstimation = this.cars[0].onewaytripCost;

  }

  openDialog (): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '250px',
      data: this.routeDetails
    });
  }
}
