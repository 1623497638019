<div class="page-title-area style-two pd-t-120 pd-b-45 bg-image bg-overlay" style="background-image:url('assets/1.jpg')">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="page-header-content">
                  <!--./ advance-search-form -->
                  <h2 class="page-title">Get In Touch
                  </h2>
              </div><!--~~./ page-header-content ~~-->
          </div>
      </div>
  </div><!--~~./ end container ~~-->
</div>

<div class="contact-info-block ptb-100">
  <div class="container">
      <!-- Title Row -->
      <div class="row align-items-center justify-content-between">
          <div class="col-lg-5">
              <div class="mock-up-thumb mrb-md-60">
                  <img src="assets/contact.jpg" alt="Contact Mock">
              </div><!-- /.mock-up-block -->
          </div><!-- /.col-lg-5 -->
          
          <div class="col-lg-7">
              <div class="contant-info-area">
                  <div class="section-title">
                      <h4 class="title-sub">BUSINESS INFORMATION</h4><!--  /.title-sub -->
                      <h2 class="title-main">Don’t Hesitate to contact with us for any information or inquiries</h2><!-- /.title-main -->
                  </div><!-- /.section-title -->
                  
                  <div class="contant-info-content hg-fadeInUp animated" data-animate="hg-fadeInUp" style="visibility: visible;">
                      <div class="info-address">
                          <span>1/399 Dhandayuthapani street</span>
                          chinniyampalayam coimbatore 641062
                      </div><!-- /.info-address -->
                      <div class="info-phone">
                        <a href="https://wa.me/919655741499">+91-9655741499</a>
                      </div><!-- /.info-phone -->
     
                  </div><!-- /.contant-info-content -->
                
              </div>
          </div><!-- /.col-lg-7 -->
      </div><!-- /.row -->
  </div><!-- /.container -->
</div>