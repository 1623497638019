<ng-container *ngIf="routeDetails">
  <header class="page-title-bar background parallax-background">
    <div class="container">
      <div class="row">
        <div class="hero">
          <div class="page-title">
            <h1>{{routeDetails.origin}} to <span>{{routeDetails.destination}}</span></h1>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div id="page-blog-details" class="todos-block bg-snow ptb-100">
    <div class="container ml-b-30">

      <div class="row" style="transform: none;">
        <div class="col-lg-12">
          <div class="section-title text-center">
            <div class="section-header text-center">

              <h2 class="special"><span>Looking for an outstation taxi from</span> {{routeDetails.origin}}
                <span>to</span> {{routeDetails.destination}}
              </h2>

            </div>

            <div class="divider"><span class="icon-star-full"></span><span class="icon-star-full"></span><span
                class="icon-star-full"></span></div>
          </div>
          <mat-card>
            <agm-map [zoom]="1">
              <agm-direction [origin]="routeDetails.origin" [destination]="routeDetails.destination"
                (onResponse)="onResponses($event)">
              </agm-direction>
            </agm-map>

            <br>

            <h2>
              Types of outstation cabs from {{routeDetails.origin}} to {{routeDetails.destination}}
            </h2>
            <p> We offer a wide range of fleet options for passengers opting for outstation taxis anywhere across
              Tamilnadu
              to
              ensure maximum comfort and safety.</p>

            <h6>One Way Trip From {{routeDetails.origin}} to
              {{routeDetails.destination}}</h6>

            <h4 class="color-primary">Total KM : {{totalDistance}} KM</h4>


            <div class="upcoming-events">
              <div class="table-responsive pd-t-20 mt-15">
                <table class="table">
                  <tbody>
                    <tr *ngFor="let car of cars">
                      <td><img src="assets/{{car.carType}}_a.png" alt=""></td>
                      <td>{{car.carType | uppercase}}
                        <a href="#">{{car['one-way']}} ₹/ KM </a>
                      </td>
                      <td>Driver Fare
                        <a href="#">{{car.onewayDriverFee}} ₹</a>
                      </td>

                      <td>Trip Cost
                        <a href="#">{{car.onewaytripCost | currency: 'INR'}} ₹</a>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
            <button (click)="openDialog()" class="btn btn-default" type="submit">Book Now</button>

            <h6>Round Trip From {{routeDetails.origin}} to
              {{routeDetails.destination}}</h6>
            <h4 class="color-primary">Total KM : {{totalDistance * 2}} KM</h4>
            <div class="upcoming-events">
              <div class="table-responsive pd-t-20 mt-15">
                <table class="table">
                  <tbody>
                    <tr *ngFor="let car of cars">
                      <td><img src="assets/{{car.carType}}_a.png" alt=""></td>
                      <td>{{car.carType | uppercase}}
                        <a href="#">{{car['round-trip']}} ₹/ KM </a>
                      </td>
                      <td>Driver Fare
                        <a href="#">{{car.roundTripDriverFee}} ₹</a>
                      </td>

                      <td>Trip Cost
                        <a href="#">{{car.roundtripCost | currency: 'INR'}} ₹</a>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>

            <button (click)="openDialog()" class="btn btn-default" type="submit">Book Now</button>

            <h2>Outstation taxi from {{routeDetails.origin}} to {{routeDetails.destination}} @ Just
              <span *ngIf="tripEstimation"> {{tripEstimation | currency: 'INR'}} </span>
            </h2>
            <p>Drop Taxi is a smart marketplace to compare and book reliable outstation taxis from
              {{routeDetails.origin}}
              to {{routeDetails.destination}}
              at
              the
              lowest price. We are Tamilnadu's first drop taxi booking service assuring you the best and cheapest
              outstation taxi transfers with a dedicated and best in class 24x7 customer support.
            </p>
            <h2>Why Choose Drop Taxi City for Outstation Cabs From {{routeDetails.origin}} to
              {{routeDetails.destination}}
              ?
            </h2>
            <p>
              Make the most outstanding use of the services offered by Drop Taxi City in the most excellent prices & go
              through the comforting and trouble-free journey by hiring {{routeDetails.origin}} to
              {{routeDetails.destination}} taxi at one way fare. With in
              excess of a decade of ability in car rental providers, Drop Taxi City is the best taxi service provider
              in
              the business, if you’re preparation to employ a cab.
            </p>
            <h2>
              {{routeDetails.origin}} to {{routeDetails.destination}} outstation taxi fare
            </h2>
            <p>
              The distance from {{routeDetails.origin}} to {{routeDetails.destination}} is about
              {{totalDistance}}. It take approximately {{totalDuration}} to complete the
              journey.
              SEDAN and SUN type of cabs offered by Drop Taxi City are well maintained and clean. Our courteous drivers
              will ensure that your entire journey is comfortable and memorable. Drop Taxi City offered world class cab
              service at reasonable price for all it routes including {{routeDetails.origin}} to
              {{routeDetails.destination}}. Below is a breakup of the fare
              charge for you trip from {{routeDetails.origin}} to {{routeDetails.destination}}.
            </p>
            <table class="table">

              <tbody>
                <tr>
                  <th>
                    <p>Fare Category</p>
                  </th>
                  <th>
                    <p>What does this include</p>
                  </th>
                  <th>
                    <p>How is this calculated</p>
                  </th>

                </tr>

                <tr>
                  <td>
                    <p></p>
                    Bare Fare
                    <p></p>
                  </td>
                  <td>
                    <p>This include the fuel and maintained charge for designator distance and duration of a particular
                      trip. </p>
                  </td>
                  <td>
                    <p>The base fare is calculated by multiplying the per kilometre rate for your cab with the minimum
                      assigned kilometres for your trip.</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p></p>
                    Driver Allowance
                    <p></p>
                  </td>
                  <td>
                    <p>One way trip = 300.RS For one way trip. If more than 400 KM bata Rs.600<br> Round trip= 300.RS
                      Per
                      day for round trip. </p>
                  </td>
                  <td>
                    <p>Driver allowance is calculated by multiplying the daily driver allowance with the number of days
                      you
                      engage your cab.</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p></p>
                    State Permit
                    <p></p>
                  </td>
                  <td>
                    <p>SEDAN Type Vehicle = 350.RS <br>SUV Type Vehicle = 1200.RS</p>
                  </td>
                  <td>
                    <p>Vehicle is moving from one state to another state permit is mandatory</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <h2>FAQ: Outstation Taxi from {{routeDetails.origin}} to {{routeDetails.destination}}
            </h2>
            <br>

            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How do I get to {{routeDetails.destination}} by outstation taxi from {{routeDetails.origin}}?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                  You can book a cab from {{routeDetails.origin}} to {{routeDetails.destination}} on our website. The
                  quick,hassle-free online booking
                  system
                  means that you can easily book your taxi either in advance or at the last minute. We also have a 24x7
                  call/WhatsApp booking service at <a href="tel:+919655741499">+91-9655741499 </a>.
                </p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How much time does it take to travel by outstation taxi from {{routeDetails.origin}} to
                    {{routeDetails.destination}}?

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Approximately it will take 9 h 3 min to reach {{routeDetails.origin}} to {{routeDetails.destination}}
                  by taxi.
                </p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Are toll fares and taxes included in {{routeDetails.origin}} to {{routeDetails.destination}} Taxi
                    Fare?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>No, the cab fare from {{routeDetails.origin}} to {{routeDetails.destination}} is exclusive of Toll
                  fares and road taxes customers are
                  expected to pay the extra fee to the driver wherever applicable.
                </p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    List of Covid-19 Safety precautions taken for the trip from {{routeDetails.origin}} to
                    {{routeDetails.destination}}


                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>The health and safety of our passengers, drivers, and our employees is our top priority. Hence we
                  have
                  taken stringent safety measures consistent with the safety guidelines by Indian Govt. Drop Taxi City
                  S.A.F.E rides: <br>
                  S - Sanitisers in cab <br>
                  A - Aarogya Setu App for drivers<br>
                  F - Face Mask mandated<br>
                  E - Ensuring disinfected cabs<br>

                </p>
                <p>
                  To find out more about the safety measures taken by us, visit our COVID-19 safety page.
                </p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How do I cancel my taxi from {{routeDetails.origin}} to {{routeDetails.destination}} in case of any
                    change of plans?


                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>You can give us a call at <a href="tel:+919655741499">+91-9655741499 </a> or you can email us at
                  droptaxicity@gmail.com and we will cancel
                  your booking. We understand that plans can change, which is why we have a 24-hour cancellation policy.
                  We
                  won’t charge you for rides canceled before 24 hours of the trip time.
                </p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    What type of vehicles are there?

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>SEDAN Model: Swift Dzire, Toyota Etios, Hyundai Xcent, Tata Zest.
                </p>
                <p>
                  2. SUV Model: Xylo, Innova, Tavera
                </p>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>