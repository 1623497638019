<!-- ======= Footer ======= -->

<footer id="page-footer">
    <div class="container">
        <div class="row">
            <div class="footer-inner clearfix">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <ul class="social-widget-footer list-inline">
                        <li class="wow zoomIn" data-wow-delay="0.6s"> Copyright © 2023 Outstation Drop Taxi. All Rights
                            Reserved.
                        </li>

                    </ul>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="copyright wow fadeIn" data-wow-delay="1.2s">
                        <a href="http://www.calvincareinfotech.com" target="_blank">Developed By Calvin Care Website
                            Designing Company</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- row -->
    </div>
    <!-- container -->
</footer>
<!-- footer -->