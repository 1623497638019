import { PLACES } from './../../places';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-city-listing',
  templateUrl: './city-listing.component.html',
  styleUrls: ['./city-listing.component.css']
})
export class CityListingComponent implements OnInit {
  places: any;
  city;

  constructor( private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.city = this.activeRoute.snapshot.paramMap.get('cityName');
    this.places = PLACES.filter(citys => citys.city === this.city)
  }
  

}
