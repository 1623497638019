import { Component, OnInit } from '@angular/core';
import { CARS } from 'src/app/cars';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.css']
})
export class TariffComponent implements OnInit {
  cars = CARS;

  constructor() { }

  ngOnInit(): void {
  }

}
