<mat-card class="text-center">
  <div class="wrap text-center">
    <div class="image text-center">
      <img src="/assets/tick.png">
    </div>


    <h4> Thank you for choosing One Way Drop Taxi</h4>


    <p> Our customer representative will check the vehicle availability and contact you for current
      (Today/Tomorrow). <br>
      <br> For
      advance booking driver details will share one day before your travel date. <br>
    </p>
  </div>
</mat-card>