<!-- Main Header -->
<header id="main-header" class="background gradient-background">
  <!-- Gradient Background -->
  <div class="container">
    <div class="row">

      <!-- Social Widget Header -->
      <div class="social-widget-header">
        <mat-horizontal-stepper class="advance-search-form" linear #stepper>
          <mat-step>
            <!-- Typed JS -->
            <div class="typed-content">
              <span>Book Your</span>
              <div id="in-details">
                <!-- ^1000 = 1 second stay-->
                <span>Outstation cabs</span>
                <span>One Way Taxi</span>
              </div>
              <span id="type"></span>
            </div>
            <div class="stepper-content">
              <div class="contact-form">
                <form class="contact-page-form style-01" novalidate="novalidate">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <input type="text" name="telephone" placeholder="Enter your pickup point" class="form-control"
                          required="" aria-required="true" #origin>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group no-margin">
                        <input type="text" name="telephone" placeholder="Enter your destination" class="form-control"
                          required="" aria-required="true" #destination>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                <ng-container *ngIf="showDirection">
                  <agm-direction [origin]="pickupPlace" [destination]="dropPlace" (onResponse)="onResponses($event)">
                  </agm-direction>
                </ng-container>
              </agm-map>

              <div class="cars">
                <div class="container">
                  <div class="carbox row">
                    <div *ngFor="let car of cars" class="col">
                      <div class="cars-item" (click)="selectedCarType(car)">
                        <label>{{car.oneway}} ₹ / Km</label>
                        <img *ngIf="car.carType !== selectedCar" src="{{car.image}}">
                        <img *ngIf="car.carType === selectedCar" src="{{car.activeImage}}">
                        <label>{{car.carType}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="btn-box text-center">
                <button class="btn btn-default" [disabled]="!requestRide" (click)="requestAride()">Request
                  a
                  ride</button>
              </div>
            </div>

          </mat-step>
          <mat-step>
            <div *ngIf="rideDetails" class="stepper-content">
              <app-ride-confirmation [rideDetails]="rideDetails"></app-ride-confirmation>
            </div>
          </mat-step>

          <mat-step>
            <app-booking-confirmation></app-booking-confirmation>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</header>

<main id="main-content">

  <!-- ======= About ======= -->

  <section id="page-about">
    <div class="container">
      <div class="row">
        <div class="my-photo">
          <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
            <img class="img-responsive" src="assets/images/my-photo.jpg" alt="Profile Picture">
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 my-short-details">
          <div class="about-tab">
            <div class="tab-content">
              <div class="tab-pane fade in active" id="mydetails">
                <div class="short-story">
                  <h2 class="story-title wow fadeIn" data-wow-delay="0.2s">A Little About Us
                  </h2>
                  <p class="story wow fadeInLeft" data-wow-delay="0.3s">
                    Outstation Drop Taxi is one of the leading cab service in Chennai, provides its services between
                    various locations across Tamil Nadu at cheapest price. We're, One of the best and trusted outstation
                    journey for a cost saving and safe journey in and around Bangalore, Chennai, across Tamil Nadu,
                    Pondicherry, Kerala, Andhra.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
  <!-- Page About -->

  <!-- ======= Services ======= -->

  <div id="page-service">
    <div class="container">
      <div class="row">

        <div class="featured-item">

          <div class="section-header text-center">
            <h2 class="special"><span>Popular</span> Cities</h2>
          </div>
          <div class="col-xs-4">
            <a routerLink="/city/chennai">
              <div class="icon">
                <img src="assets/images/city/chennai.jpg">
              </div>
              <div class="cat-name">
                Chennai
              </div>
            </a>
          </div>
          <div class="col-xs-4">
            <div class="owl-item">
              <a routerLink="/city/pondicherry">
                <div class="icon">
                  <img src="assets/images/city/pondicherry.jpg">
                </div>
                <div class="cat-name">
                  Pondicherry
                </div>
              </a>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="owl-item">
              <a routerLink="/city/bangalore">
                <div class="icon">
                  <img src="assets/images/city/bangalore.jpg">
                </div>
                <div class="cat-name">
                  Bengaluru
                </div>
              </a>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="owl-item">
              <a routerLink="/city/coimbatore">
                <div class="icon">
                  <img src="assets/images/city/coimbatore.jpg">
                </div>
                <div class="cat-name">
                  Coimbatore
                </div>
              </a>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="owl-item">
              <a routerLink="/city/madurai">
                <div class="icon">
                  <img src="assets/images/city/madurai.jpg">
                </div>
                <div class="cat-name">
                  Madurai
                </div>
              </a>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="owl-item">
              <a routerLink="/city/kerala">
                <div class="icon">
                  <img src="assets/images/city/kerela.jpg">
                </div>
                <div class="cat-name">
                  Kerala
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>
  <!-- Page Service -->

  <!-- ======= testmonial with Clients ======= -->

  <div id="page-textimonal">
    <div class="container">
      <div class="row">
        <!-- Testimonial -->
        <div class="section-header text-center">
          <h2 class="special"><span>What Our</span> Customer Says</h2>
        </div>
        <div class="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-12">
          <div class="testimonial-inner center-block text-center">
            <div id="testimonial-carousel" class="owl-carousel owl-theme">
              <div class="item">
                <blockquote>
                  <p>Punctual driver, was there on time to pick me up at the airport with clear signboard Top notch
                    service...... My driver was waiting at Arrivals for me with a clear sign. He was very polite and
                    friendly and drove me with no delay</p>
                  <footer>
                    <cite>Fatima Mahmoud</cite>
                  </footer>
                </blockquote>
              </div>
              <div class="item">
                <blockquote>
                  <p>Booked a cab with Drop Taxi City from Chennai to Salem and had a safe and comfortable journey.
                    Hassle free booking, the fare was cheap and affordable compared to other taxi services. Best
                    outstation taxi service out there!</p>
                  <footer>
                    <cite>Sathish Kumar</cite>
                  </footer>
                </blockquote>
              </div>
              <div class="item">
                <blockquote>
                  <p>Decent service, fare was cheap, customer support driver was good, the cab was clean and tidy, and
                    had a good experience.</p>
                  <footer>
                    <cite>Priya</cite>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>
  <!-- Page Testimonial -->

  <!-- ======= Blog ======= -->

  <section id="page-blog">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="section-header text-center">
            <h2 class="special"><span>Popular One Way Outstation</span> Drop Taxi Routes</h2>
            <p class="small text-center">YOU CAN BOOK A DROP TAXI WITH EASE ON OUR WEBSITE
            </p>
          </div>
        </div>
        <div class="blog-post">
          <div *ngFor="let routes of  places[0].routes" class="col-lg-4 col-md-4 col-sm-6 col-xs-12 blog-box">
            <article>
              <div class="post-thumb">
                <img src="{{routes.image}}" alt="" srcset="">
                <!-- <agm-map [zoom]="1">
                  <agm-direction [origin]="routes.origin" [destination]="routes.destination">
                  </agm-direction>
                </agm-map> -->
              </div>
              <div class="post-content">
                <a href="#">
                  <h4><a routerLink="/city/{{routes.origin | lowercase }}/{{routes.url}}">{{routes.origin}}
                      to
                      {{routes.destination}}</a></h4>
                </a>
                <p>One Way Trip Cost - {{routes.estimation | currency: 'INR'}}</p>
                <p>Total Distance: {{routes.distance}}</p>
                <p>Total Duration: {{routes.duration}}</p>
                <div class="read-more">
                  <a class="btn btn-md pull-right" (click)="openDialog(routes)">Book Now</a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
  <!-- Page Blog -->

  <!-- ======= Contact ======= -->

  <section id="page-contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="section-header text-center">
            <h2 class="special"><span>Con</span>tact</h2>
            <p class="small text-center">Please don't hesitate to contact us for <br>any information</p>
          </div>
        </div>
        <!-- Contact Info -->
        <div class="contact-info">
          <div class="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2">
            <div class="col-md-4 col-sm-4 col-xs-12 text-center wow fadeInDown" data-wow-delay="0.2s">
              <div class="form-icon-box-gap">
                <div class="form-icon-box">
                  <i class="arrow-box fa fa-envelope"></i>
                </div>
                <div class="box-details">
                  <p><a href="tel:+919655741499">9655741499</a> <br></p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 text-center wow fadeInDown" data-wow-delay="0.4s">
              <div class="form-icon-box-gap">
                <div class="form-icon-box">
                  <i class="arrow-box fa fa-phone"></i>
                </div>
                <div class="box-details">
                  <p>mahacabs83@gmail.com</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 text-center wow fadeInDown" data-wow-delay="0.6s">
              <div class="form-icon-box-gap">
                <div class="form-icon-box">
                  <i class="arrow-box fa fa-map-marker"></i>
                </div>
                <div class="box-details">
                  <p>Tirupattur, Tamil Nadu</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- Container -->
  </section>
  <!-- Page Contact -->

</main>