<header class="page-title-bar background parallax-background">
  <div class="container">
    <div class="row">
      <div class="hero">
        <div class="page-title">
          <h1>About Outstation<span> Drop Taxi</span></h1>
        </div>
      </div>
    </div>
  </div>
</header>

<div id="page-blog-details" class="contact-info-block ptb-100">
  <div class="container">
    <!-- Title Row -->
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-12">
        <div class="contant-info-area">
          <div class="section-title">
            <h2 class="title-main">Popular outstation taxi service access Chennai, Tamilnadu
            </h2><!-- /.title-main -->

            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Package</th>
                  <th>One Way / KM</th>
                  <th>Round Trip / KM</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let car of cars">
                  <td> {{car.carType | uppercase }} ( {{car.availableCars }} )</td>
                  <td> {{car['one-way'] | currency: 'INR' }} </td>
                  <td> {{car['round-trip'] | currency: 'INR' }}</td>
                </tr>
              </tbody>
            </table>

            <div class="col-md-12">
              <h4>Rates Given Above </h4>
              <p>Toll fees,Inter-State Permit charges (if any) are extra. </p>
              <p>Drop Trips-Driver Bata Rs.300.- Waiting Charges Rs.100 per hour. </p>
              <p>Drop Trips-Minimum running must be 130kms per day. </p>
              <p>Round Trips-Driver Bata Rs.300/- per day. </p>
              <p>Round Trips-Minimum running must be 250kms per day.For Bengaluru it is minimum
                300kms per day. </p>
              <p>Hill station charges - Rs.300 </p>
              <p> 1 day means 1 calender day (from midnight 12 to Next Midnight 12) </p>
            </div>
          </div><!-- /.section-title -->


        </div>
      </div><!-- /.col-lg-7 -->
    </div><!-- /.row -->
  </div><!-- /.container -->
</div>