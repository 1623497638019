import { CARS } from './../../cars';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TaxiService } from 'src/app/taxi.service';
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';


@Component({
  selector: 'app-ride-confirmation',
  templateUrl: './ride-confirmation.component.html',
  styleUrls: ['./ride-confirmation.component.css']
})
export class RideConfirmationComponent implements OnInit {

  @Input() rideDetails;
  public dateControl = new FormControl(new Date());

  public date = Date.now();

  journeyType = ['One Way', 'Round Trip'];
  cars = CARS;
  public selectedMoment = new Date();
  filterGroup;
  selectedDate = new Date();
  selectedTime = new Date();
  selectedCar;
  selectedTripType = 'One Way';
  driverBata;
  baseFare;
  estimation;
  secondFormGroup: FormGroup;
  email = 'mahacabs83@gmail.com';
  parseFloat: any;
  whatsupText;
  wpNumber = '+919655741499'
  isBrowser: boolean;

  // private firestore: AngularFirestore
  constructor(
    private _formBuilder: FormBuilder,
    private taxiService: TaxiService,
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: any) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {

    if (this.rideDetails) {
      this.getEstimation(this.rideDetails);
    }
    this.secondFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]]
    });

  }

  get name() {
    return this.secondFormGroup.get('name');
  }
  get phone() {
    return this.secondFormGroup.get('phone')
  }

  onTripChange(value) {
    this.selectedTripType = value;
    this.getEstimation(this.rideDetails);
  }

  onCarChange(value) {
    this.rideDetails.carType = value;
    this.getEstimation(this.rideDetails);
  }

  getEstimation(routes) {
    this.selectedCar = CARS.filter(car => car.carType === routes.carType)[0];

    if (this.selectedTripType === 'One Way') {
      this.driverBata = this.selectedCar.onewayDriverFee;
      this.baseFare = this.selectedCar.oneway;
      this.estimation = this.selectedCar.oneway * routes.distance.replace(/[^0-9.-]+/g, "") + this.selectedCar.onewayDriverFee;
    }
    if (this.selectedTripType === 'Round Trip') {
      this.driverBata = this.selectedCar.roundTripDriverFee;
      this.baseFare = this.selectedCar.roundtrip;
      this.estimation = this.selectedCar.roundtrip * (routes.distance.replace(/[^0-9.-]+/g, "") * 2) + this.selectedCar.roundTripDriverFee;
    }
  }

  bookNow() {
    let url = 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail';

    let smsKey = 'ltf8iELu0wKAIdF9SPypkB6M1bHnrCxNoDgZVXRme2caj7h4sTVH2Rba7yqwNmK10D8Irp5sQE34dJYo';
    let bookingId = Math.floor((Math.random() * 1000) + 10);

    let bookingDetails = {
      'bookingId': bookingId,
      'name': this.secondFormGroup.get('name').value,
      'phoneNo': this.secondFormGroup.get('phone').value,
      'origin': this.rideDetails?.origin,
      'originCityName': this.rideDetails?.origin,
      'destination': this.rideDetails?.destination,
      'destinationCityName': this.rideDetails?.destination,
      'distance': this.rideDetails?.distance,
      'carType': this.rideDetails?.carType,
      'bookedDate': moment(this.selectedDate).format('MM/DD/YYYY'),
      'bookedTime': 'NA',
      'estimate': this.estimation,
      'duration': this.rideDetails?.duration,
      'tripType': this.selectedTripType,
      'driverBatta': 'NA',
      'ratePerKM': 'NA',
      'returnDate': 'NA',
      'adminEmail': 'mahacabs83@gmail.com',
      'adminPhone': '+919655741499',
      'adminWhatsapp': '+919655741499',
      'websiteAddress': 'www.jmtravels.in',
      'siteName': 'JM Travels',
      'siteInfo': '',
      'adminEmailSubject': `Website Confirmation From ${this.secondFormGroup.get('name').value}`,
      'logoURL': ''
    }

    this.http.post(url, bookingDetails).subscribe(res => console.log(res));

    let smsMessage = `
    Website Enquity\r\n
    Booking ID: ${bookingDetails.bookingId}\r\n
    Name: ${bookingDetails.name}\r\n
    Mobile Number: ${bookingDetails.phoneNo}\r\n
    Pickup Location: ${bookingDetails.origin}\r\n
    Drop Location: ${bookingDetails.destination} \r\n
    Pickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime} \r\n
    Trip Type: ${bookingDetails.tripType} \r\n
    Total KM: ${bookingDetails.distance} KM\r\n
    Total Trip Fare: ${bookingDetails.estimate} ₹\r\n
    Rate Per KM: ${bookingDetails.ratePerKM} ₹\r\n
    Driver Batta: ${bookingDetails.driverBatta} ₹\r\n
    Toll, parking, permit extra \r\n 
    For any questions please contact ${this.wpNumber} \r\n 
    https://www.mahacabs.com`;


    let whatsappMessage = window.encodeURIComponent(smsMessage);

    let whatsupText = `https://api.whatsapp.com/send?phone=${this.wpNumber}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");

  }

}
