import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaxiService {

  bookingId = new BehaviorSubject(null);
  nextStep = new BehaviorSubject(null);
  tariff = new BehaviorSubject(null);

  constructor() { }
}
