import { CARS } from './../../cars';
import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PLACES } from 'src/app/places';
import { TaxiService } from 'src/app/taxi.service';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
var siteRoute = [];
declare var $;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 1;
  cars = CARS;
  selectedCar;
  origin;
  destination;
  showDirection = false;
  pickupPlace;
  dropPlace;
  totalDistance;
  totalDuration;
  requestRide = false;
  rideDetails;
  name;
  phoneNo;
  places = PLACES;
  routeTotalDistance;
  routeTotalDuration;
  siteRoute = siteRoute;

  constructor(
    private _formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private taxiService: TaxiService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.taxiService.nextStep.subscribe(nextStep => {
      if (nextStep) {
        this.nextStep();
      }
    });

  }

  ngAfterViewInit() {
    $.getScript("./assets/js/script.js");


    this.mapsAPILoader.load().then(() => {
      // this.getDistance();
      // this.siteRoute = siteRoute;
      const origin = this.originElementRef?.nativeElement;
      const destination = this.destinationElementRef?.nativeElement;
      this.addMapEventListener(origin, 'origin');
      this.addMapEventListener(destination, 'destination');
    });

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

  }


  selectedCarType(ev) {
    this.selectedCar = ev.carType;
    (this.origin && this.destination) ? this.requestRide = true : this.requestRide = false;
  }

  addMapEventListener(input, type) {

    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'origin') ? this.origin = place.formatted_address : this.destination = place.formatted_address

        if (this.origin && this.destination) {
          this.showDirection = true;
          this.pickupPlace = this.origin;
          this.dropPlace = this.destination;
          (this.selectedCar) ? this.requestRide = true : this.requestRide = false;
        }

        this.zoom = 12;
      });
    });
  }

  onResponses(event) {
    this.totalDistance = event.routes[0].legs[0].distance.text;
    this.totalDuration = event.routes[0].legs[0].duration.text;
  }

  getDistance() {
    let distanceService = new google.maps.DistanceMatrixService();
    let chennai = PLACES[0];

    let dis = {
      origins: [],
      destinations: [],
      travelMode: 'DRIVING',
    }
    dis.origins = [chennai.city];
    dis.destinations = chennai.routes.map(routes => {
      return routes.destination
    });
    distanceService.getDistanceMatrix(dis as any, this.callback);
  }

  callback(response, status) {
    if (status == 'OK') {
      var origins = response.originAddresses;
      var destinations = response.destinationAddresses;

      for (var i = 0; i < origins.length; i++) {
        var results = response.rows[i].elements;
        for (var j = 0; j < results.length; j++) {
          let route = {
            'origin': '',
            'destination': '',
            'distance': '',
            'duration': '',
            'estimation': 0
          }
          var element = results[j];
          route.distance = element.distance.text;
          route.duration = element.duration.text;
          route.origin = origins[i].split(',')[0];
          route.destination = destinations[j].split(',')[0];
          route.estimation = 12 * parseFloat(route.distance) + 300;
          siteRoute.push(route);
          console.log(siteRoute)
        }
      }
    }
  }

  requestAride() {
    this.rideDetails = {
      'origin': this.origin,
      'destination': this.destination,
      'distance': this.totalDistance.replace(/[^0-9.-]+/g, ""),
      'duration': this.totalDuration,
      'carType': this.selectedCar
    }

    if (this.rideDetails.distance >= 130) {
      this.stepper.next();
      this.scrollToSectionHook();
    } else {
      alert('Distance should be greater than 130 KM')
    }
  }

  private scrollToSectionHook() {
    const element = document.querySelector('body');
    console.log(element);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth', block: 'start', inline:
            'nearest'
        });
        console.log('scrollIntoView');
      }, 250);
    }

  }

  nextStep() {
    this.stepper.next();
  }

  openDialog(routes): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '250px',
      data: routes
    });
  }
}
