<!-- Navigation Menu -->
<div id="navigation" class="navbar-inverse navbar-fixed-top animated-header">
  <div class="container">
    <div class="navbar-header">
      <!-- Navbar Menu Icon -->
      <div class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
        <span class="burg"></span>
      </div>
      <!-- Brand Name -->
      <h1 class="navbar-brand logo">
        <a routerLink="/"><img src="assets/logo.png"></a>
      </h1>
    </div>
    <!-- Header Nav Menu -->
    <nav class="collapse navbar-collapse navbar-scrollspy navbar-right">
      <ul id="nav" class="nav navbar-nav">
        <li><a routerLink="/" class="fast-scroll">Home</a></li>
        <li><a routerLink="/about" class="fast-scroll">About</a></li>
        <li><a routerLink="/tariff" class="fast-scroll">Tariff</a></li>
      </ul>
    </nav>
  </div>
  <!-- container -->
</div>
<!-- Navigation -->